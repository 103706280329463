import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default { 
    getAll(callback){

    axios.get(myConfig.api_url+`/api/v1/allAnnees?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getAllNoToken(callback){

        axios.get(myConfig.api_url+`/api/v1/allAnneesNoToken`)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
    
        },

    getCurrentAnnee(callback){

        axios.get(myConfig.api_url+`/api/v1/getCurrentAnnee?api_token=${token}`)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });

    },

    getAllPeriodes(callback){

        axios.get(myConfig.api_url+`/api/v1/getAllPeriodes?api_token=${token}`)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
    
        },

    add(args,callback){

        //designation:? , debut:?, fin:?,
        axios.post(myConfig.api_url+`/api/v1/addAnnee?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    addPeriode(args,callback){

        //designation:? , debut:?, fin:?, annee_idannee: ?
        axios.post(myConfig.api_url+`/api/v1/createAnneePeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    updatePeriode(args,callback){

        //designation:? , debut:?, fin:?, annee_idannee: ? , id: ?
        axios.post(myConfig.api_url+`/api/v1/updateAnneePeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deletePeriode(args,callback){

        //id: ?
        axios.post(myConfig.api_url+`/api/v1/deleteAnneePeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    update(args,callback){
        //id:?, designation:?, debut:?, fin:?,
        axios.post(myConfig.api_url+`/api/v1/updateAnnee?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    delete(args,callback){
        //id:? ,
        axios.post(myConfig.api_url+`/api/v1/deleteAnnee?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getAnneePeriodesByIdAnne(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getAnneePeriodesByIdAnne?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
}