import myConfig from './myConfig';
import axios from 'axios';


export default {
    login(args,callback){

        axios.post(myConfig.api_url+'/api/v1/login',args).then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    loginProf(args,callback){

        axios.post(myConfig.api_url+'/api/v1/loginProf',args).then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}