<template>
  <router-view/>
</template>
<script>
import $ from 'jquery';
import bootstrap from 'bootstrap';
import popper from 'popper.js';
window.jQuery =  window.$ = $;
window.Popper = require;


export default {
  
}
</script>
<style>

</style>
